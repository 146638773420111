import React, {ReactNode, useEffect, useState} from 'react';
import FilterOptionsType from '../../../lib/consultant/FilterOptionsType';
import FilterType from '../../../lib/consultant/FilterType';
import useSortedFilterOptions from '../../../lib/hooks/useSortedFilterOptions';
import {MessageToolView} from "../../../lib/enum/MessageToolView";
import MessageType from "../../../lib/MessageType";
import MessagetoolConversation from "../messagetool-conversation";
import evaluateView from "../../../lib/utils/evaluateView";
import MessagetoolInboxConsultant from "../messagetool-inbox-consultant";
import ConsultantNewMessageForm from "../../molecules/consultant-new-message-form";
import ConsultantAnswerForm from "../../molecules/consultant-answer-form";

interface MessagetoolConsultantProps {
    answerAction: string;
    filterOptions: FilterOptionsType;
    messagesAction: string;
    nameByVersIntIdAction: string;
    loggedInConsultantId: number;
    saveMessageUpdateAction: string;
    sendMessageAction: string;
    updateReadStatusAction: string;
}

const MessagetoolConsultant = ({
    answerAction, filterOptions, messagesAction, nameByVersIntIdAction, loggedInConsultantId, saveMessageUpdateAction, sendMessageAction, updateReadStatusAction,
}: MessagetoolConsultantProps) => {
    const baseClassName = 'w-messagetoolConsultant';
    const [selectedMessage, setSelectedMessage] = useState<MessageType>(null);
    const [view, setView] = useState<MessageToolView>((() => evaluateView()));

    useEffect(() => {
        if (view === MessageToolView.NEW_MESSAGE) {
            window.history.replaceState(null, '', '#' + MessageToolView.NEW_MESSAGE);
            return;
        }
        if (view === MessageToolView.CONVERSATION) {
            window.history.replaceState(null, '', '#' + MessageToolView.CONVERSATION);
            return;
        }

        // Redirect to default view
        window.history.replaceState(null, '', '#' + MessageToolView.INBOX);
    }, [view]);

    const defaultFilter: FilterType = {
        subject: null,
        consultantId: loggedInConsultantId,
        status: filterOptions?.statusList[0],
        search: '',
    };
    const [filter, setFilter] = useState(defaultFilter);

    const sortedFilterOptions = useSortedFilterOptions(filterOptions);

    const backToInbox = () => {
        setView(MessageToolView.INBOX);
    };

    const getViewComponent = (): ReactNode | undefined => {
        switch (view) {
            case MessageToolView.INBOX:
                return <MessagetoolInboxConsultant
                    switchView={setView}
                    setSelectedMessage={setSelectedMessage}
                    messagesAction={messagesAction}
                    filter={filter}
                    filterOptions={filterOptions}
                    setFilter={setFilter}
                    saveMessageUpdateAction={saveMessageUpdateAction}
                />
            case MessageToolView.NEW_MESSAGE:
                return <ConsultantNewMessageForm
                    backToInbox={backToInbox}
                    loggedInConsultantId={loggedInConsultantId}
                    nameByVersIntIdAction={nameByVersIntIdAction}
                    sendMessageAction={sendMessageAction}
                    selectOptions={sortedFilterOptions}
                    setSelectedValues={setFilter}
                />
            case MessageToolView.CONVERSATION:
                if (null === selectedMessage) {
                    setView(MessageToolView.INBOX);
                    return;
                }
                return <MessagetoolConversation
                    message={selectedMessage}
                    switchView={setView}
                    updateReadStatusAction={updateReadStatusAction}
                    answerForm={
                        (addAnswer) => <ConsultantAnswerForm
                            addAnswer={addAnswer}
                            allowAnswerOnBehalfOf={selectedMessage.consultantId !== loggedInConsultantId}
                            answerAction={answerAction}
                            clientName={selectedMessage.client.clientName}
                            messageId={selectedMessage.id}
                            messageTheme={selectedMessage.subject}
                            statusList={filterOptions.statusList}
                            themeList={filterOptions.subjectList}
                            loggedInConsultantId={loggedInConsultantId}
                            consultantList={filterOptions.consultantList}
                            messageStatus={selectedMessage.status}
                        />
                    }
                />
        }
    }

    return (
        <div className={`${baseClassName}`}>
            {getViewComponent()}
        </div>
    );
};

export default MessagetoolConsultant;
