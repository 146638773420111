import React, {ReactNode, useCallback, useEffect, useRef, useState,} from 'react';
import './index.scss';
import FormButton, {
    FormButtonVariation
} from '../../../../../../../../design/1/js/templates/atoms/form-fields/form-button';
import TableHead from '../../../../../../../../design/1/js/templates/atoms/table-head';
import Table from '../../../../../../../../design/1/js/templates/molecules/table';
import TableBody from '../../../../../../../../design/1/js/templates/atoms/table-body';
import useMessageLoad from '../../../lib/hooks/useMessageLoad';
import FilterType from '../../../lib/consultant/FilterType';
import MessageType from '../../../lib/MessageType';
import {MessageToolView} from "../../../lib/enum/MessageToolView";
import {MessageStatus} from "../../../lib/enum/MessageStatus";

interface BaseMessagetoolInboxProps {
    messageListTitleRow: () => ReactNode;
    messageListEntry: (updateMessage: (message: MessageType) => void, message: MessageType, ref: (node: Element) => void | undefined, key: string) => ReactNode,
    filter?: FilterType;
    filterComponent?: () => ReactNode;
    messagesAction: string;
    switchView: (view: MessageToolView) => void;
    notFoundMessage: string;
    splitMessages?: boolean;
}

const BaseMessagetoolInbox = ({
    messageListTitleRow, messageListEntry, messagesAction, switchView, filter, filterComponent, notFoundMessage, splitMessages = false,
}: BaseMessagetoolInboxProps) => {
    const baseClassName = 'w-baseMessagetoolInbox';
    const [pageNumber, setPageNumber] = useState(1);

    const {
        loading,
        messageList,
        hasMore,
        setMessages,
    } = useMessageLoad(messagesAction, pageNumber, filter);

    const observer = useRef<IntersectionObserver>();

    useEffect(() => {
        setPageNumber(1);
    }, [filter]);

    const lastMessageRef = useCallback((node: Element) => {
        if (loading) {
            return;
        }

        if (observer.current) {
            observer.current.disconnect();
        }

        const observerCallback: IntersectionObserverCallback = (entries => {
            if (entries[0].isIntersecting && hasMore) {
                setPageNumber(prevPageNumber => prevPageNumber + 1);
            }
        });
        observer.current = new IntersectionObserver(observerCallback);

        if (node) {
            observer.current.observe(node);
        }
    }, [loading, hasMore]);

    const updateMessage = newMessage => {
        setMessages(currentMessages => {
            const tempMessages = [...currentMessages];
            const messageIndex = tempMessages.findIndex(message => message.id === newMessage.id);
            tempMessages[messageIndex] = newMessage;

            return tempMessages;
        });
    };

    const handleNewMessage = () => {
        switchView(MessageToolView.NEW_MESSAGE);
    };

    const messagesDone = splitMessages
        ? messageList.filter((message) => message.status === MessageStatus.Done)
        : [];
    const messagesNotDone = splitMessages
        ? messageList.filter((message) => message.status !== MessageStatus.Done)
        : messageList;

    const renderMessageTable = (
        messages: MessageType[],
        attachRef: boolean
    ) => {
        return (
            <Table>
                <TableHead>{messageListTitleRow()}</TableHead>
                <TableBody>
                    {messages.length ? (
                        messages.map((message, index) => {
                            const isLastMessage = index === messages.length - 1;
                            const ref = attachRef && isLastMessage ? lastMessageRef : undefined;
                            return messageListEntry(
                                updateMessage,
                                message,
                                ref,
                                `parentItem-${message.id}`
                            );
                        })
                    ) : (
                        !loading && <div className="row">{notFoundMessage}</div>
                    )}
                </TableBody>
            </Table>
        );
    };

    return (
        <div className={`${baseClassName}`}>
            <div className={`${baseClassName}__header`}>
                <h1>{window.sv_resource.get('plf_messagetool_title')}</h1>
                <FormButton
                    className={`${baseClassName}__headerButton`}
                    handleClick={handleNewMessage}
                    text={window.sv_resource.get('plf_messagetool_new_message_btn_label')}
                    type="button"
                    variation={FormButtonVariation.Primary}
                    hasIcon={false}
                />
            </div>
            {filterComponent && filterComponent()}

            {splitMessages ? (
                <>
                    {renderMessageTable(messagesNotDone, true)}

                    {messagesDone.length > 0 && (
                        <>
                            <h2>{window.sv_resource.get('plf_messagetool_client_archived')}</h2>
                            {renderMessageTable(messagesDone, false)}
                        </>
                    )}
                </>
            ) : (
                renderMessageTable(messagesNotDone, true)
            )}

            <div>{loading && window.sv_resource.get('plf_messagetool_client_loading')}</div>
        </div>
    );

};
BaseMessagetoolInbox.defaultProps = {
    filter: [],
    filterComponent: undefined,
};

export default BaseMessagetoolInbox;
