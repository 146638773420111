import './admin'
import '../scss/app.scss';
import 'jquery-migrate';
import '@popperjs/core';
import 'bootstrap';
import initializeLayout from './templates/initializeLayout';
import initializeFrontpage from './templates/initializeFrontPage';
import styleFrontPage from './templates/styleFrontPage';
import useJqueryGlobal from './lib/resource-global';
import makeTablesScrollable from '../templates/inc/view/content/content';
import '../../../platform/widgets/view/index';

useJqueryGlobal();
initializeLayout();
initializeFrontpage();
styleFrontPage();
makeTablesScrollable();

// Remove elements if there is an ongoing maintenance period
window.addEventListener('DOMContentLoaded', () => {
    const frontpageWrapper: HTMLDivElement = document.querySelector('.frontpage-wrapper');
    const isMaintenance: boolean = Boolean(frontpageWrapper?.dataset.isMaintenance);
    if (!isMaintenance) {
        return;
    }

    const loginBox = document.querySelector('.plf-login-box');
    const registerBox = document.querySelector('.plf-register-box');
    loginBox.remove();
    registerBox.remove();
});
