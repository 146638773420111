async function svConsultantMigrateInbox(e: Event): Promise<void> {
    e.preventDefault();

    const link = new URL((e.currentTarget as HTMLAnchorElement).href);

    addOverlay();
    const modalContent = await fetchConsultantList(link);

    if (modalContent) {
        displayModal(modalContent, link);
    } else {
        removeOverlay();
    }
}

(window as any).svConsultantMigrateInbox = svConsultantMigrateInbox;

function displayModal(modalContent: string, link: URL): void {
    document.body.insertAdjacentHTML('beforeend', modalContent);

    const modal = document.body.querySelector<HTMLDivElement>('[data-sv-consultant-migrate-inbox-modal]');
    const cancelButtons = modal.querySelectorAll<HTMLButtonElement>('[data-migrate-cancel]');
    const confirmButton = modal.querySelector<HTMLButtonElement>('[data-migrate-confirm]');
    const select = modal.querySelector<HTMLSelectElement>('#consultantTo');

    cancelButtons.forEach((button) => {
        button.addEventListener('click', () => {
            modal.remove();
            removeOverlay();
        }, { once: true });
    });

    confirmButton.addEventListener('click', () => {
        confirmButton.disabled = true;
        link.searchParams.set('fromId', select.value);
        executeInboxesMigration(link);
    }, { once: true });

    select.addEventListener('change', () => {
        confirmButton.disabled = false;
    }, { once: true });
}

function addOverlay(): void {
    let overlay = document.querySelector('[data-sv-consultant-migrate-inbox-overlay]');
    if (!overlay) {
        overlay = document.createElement('div');
        overlay.className = 'modal-overlay';
        overlay.setAttribute('data-sv-consultant-migrate-inbox-overlay', '')
        document.body.appendChild(overlay);
    }
}

function removeOverlay(): void {
    let overlay = document.querySelector('[data-sv-consultant-migrate-inbox-overlay]');
    if (overlay) {
        overlay.remove();
    }
}

const fetchConsultantList = async (link: URL): Promise<string> => {
    const toId = link.searchParams.get('toId');

    const response = await fetch('/_admin/user/getConsultantList?toId=' + toId, {
        method: 'GET',
        headers: {
            'Content-Type': 'text/html',
        },
    });

    return response.text();
};

async function executeInboxesMigration(link: URL): Promise<void> {
    await fetch(link.toString(), {
        method: 'POST',
        headers: {
            'X-Csrf-Token': document.body.dataset.csrfToken,
        },
    });

    window.location.reload();
}
